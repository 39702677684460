import axios from 'axios';

class PointsApi {
    async addEventPoints() {
        try {
            const result = await axios.get('/add_points/event_finish');
            return result.data;
        } catch (err) {
            console.log(err);
            alert(`Ошибка запроса ${err.message}`);
            return {
                error: 'Server error'
            };
        }
    }
}

export default new PointsApi();