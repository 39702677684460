<template>
    <b-container>
        <al-card class="get-score__card">
            <div class="heading" v-if="!isLoading">
                {{ errorMessage ? 'Байты не могут быть получены!' : 'Байты за прохождение задания успешно получены!' }}
            </div>
            <div class="heading" v-else>
                Подождите...
            </div>
            <div class="description" v-if="getErrorMessage">{{ getErrorMessage }}</div>
            <al-btn :to="`/event-online/${nearestEvent?.id}`">Вернуться обратно</al-btn>
        </al-card>
    </b-container>
</template>
<script>
import AlCard from '@/components/AlCard';
import AlBtn from '@/components/AlBtn';
import PointsApi from '@/services/points';
import EventApi from '@/services/event';
import {isAfter, parseJSON} from 'date-fns';

export default {
    name: 'GetPoints',
    components: { AlCard, AlBtn },
    data: () => ({
        errorMessage: '',
        nearestEvent: {},
        isLoading: true,
    }),
    computed: {
        getErrorMessage() {
            if (this.errorMessage === 'points not added') {
                return 'Байты уже получены';
            }
            if (this.errorMessage === 'no current events') {
                return 'Задание еще не пройдено';
            }
            return null;
        },
    },
    async created() {
        const responseStatus = await PointsApi.addEventPoints();
        this.isLoading = false;
        if (responseStatus?.error) {
            this.errorMessage = responseStatus?.error;
        }
        const allEvents = await EventApi.getAllEvents();
        this.nearestEvent = allEvents
            .filter(el => isAfter(parseJSON(el.end_at), Date.now()))
            .sort((a, b) => parseJSON(b.begin_at).getTime() - parseJSON(a.begin_at).getTime())
            .pop();
    },
};
</script>
